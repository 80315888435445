<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
      <v-dialog v-model="appConfirmation" width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          <span style="font-size:16px;font-weight:600">Confirm here to change status as replied ?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00a972" text @click="appConfirmation = false">Cancel</v-btn>
          <v-btn color="red" text @click="changeMode">Change Status</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md12>
        <v-card id="app_height" outlined tile>
          <v-layout pa-4 wrap justify-center>
            <v-flex text-center>
              <span style="font-size:16px;font-weight:600">Query Details</span>
            </v-flex>
          </v-layout>
          <v-layout px-5 wrap justify-center align="center">
            <v-flex>
              <v-card>
                <table class="logtable" style width="100%" v-if="query != null">
                  <tr>
                    <th>Date</th>
                    <td>{{queryDate}}</td>
                  </tr>
                  <tr>
                    <th>First Name</th>
                    <td>{{query.firstname}}</td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td>{{query.lastname}}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{{query.email}}</td>
                  </tr>
                  <tr>
                    <th>Phone No</th>
                    <td>{{query.phonePrefix}} {{query.phone}}</td>
                  </tr>
                  <tr>
                    <th>Subject</th>
                    <td>{{query.subject}}</td>
                  </tr>
                  <tr>
                    <th>Message</th>
                    <td>{{query.content}}</td>
                  </tr>
                </table>
                
              </v-card>
            </v-flex>
            
          </v-layout>
          <v-flex v-if="query != null">
           
               <p v-if="query.status == 'new'">  <v-btn @click="checkFinal()" small text color="red" block>Click here to change status as Replied</v-btn></p>
                        <p v-else>  <v-btn small text color="green" block>Already Replied</v-btn></p>
            </v-flex>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";

export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appConfirmation: false,
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      query: null,
      queryDate: null,
    };
  },
  beforeMount() {
    this.getQuery();
  },
  methods: {
    getQuery() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/query/get",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          id: this.id
        }
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.data.status) {
            this.query = response.data.data;
            this.id = response.data.data._id;
            var dt = new Date(this.query.createddate);
            dt = dt.toString();
            dt = dt.slice(4, 25);
            this.queryDate = dt;
          } else {
            this.msg = response.data.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    },
    checkFinal: function() {
      this.appConfirmation = true;
    },
    changeMode: function(){
      this.appConfirmation = false;
       this.appLoading = true;
      var data = {};
      data["id"] = this.id;
      data["status"] = "replied";
      axios({
        method: "POST",
        url: "/query/status/",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data,
      })
        .then(response => {
          this.appLoading = false;
          this.getQuery();
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    },
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
.logtable,
.logtable th,
.logtable td {
  border: 1px solid black;
  border-collapse: collapse;
}
.logtable .logtable th,
.logtable td {
  padding: 5px;
  padding-left: 20px;
  text-align: left;
}
.logtable th {
  text-align: left;
  padding: 5px;
  padding-left: 50px;
  max-width: 80px;
}
</style>